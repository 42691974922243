import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Menu() {
  const { venueId } = useParams();
  const [data, setData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      console.log("Calledd");
      await fetch(`https://api.mekanara.com/venues/${venueId}/menu`)
        .then((response) => response.json())
        .then((response) => {
          setData(response);
        });
    };

    fetchData();
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div className="container-wrapper">
      {Object.keys(data).map((item, index) => (
        <div key={index}>
          <div class="section-heading">
            <span class="section-title">{item}</span>
          </div>
          {data[item].map((item, index) => {
            console.log("item.image.large: ", item);
            return (
              <div className="menu-item" key={item.id}>
                <div className="container" key={item.id}>
                  <div
                    className="price-container"
                    style={{
                      width: item.image ? "" : "100%",
                    }}
                  >
                    <div className="name">{item.name}</div>
                    <div className="description">{item.description}</div>
                    <div className="price">{`${item.price} \u20BA`}</div>
                  </div>
                  <div className="imageContainer">
                    {item.image && (
                      <img src={item.image.large} className="image" />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
